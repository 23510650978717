<template>
  <v-card
    class="ma-5"
    :elevation="0"
    color="grey-lighten-4"
    flat
    outlined
    :loading="loading"
  >
    <v-toolbar color="primary" :dark="true">
      <v-toolbar-title>My islands</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="pa-3">
      <v-chip-group selected-class="text-primary" column>
        <v-chip
          v-for="m in allowed_campaigns"
          :key="m.id"
          @click="campaign_changed(m)"
        >
          {{ m.name }}
        </v-chip>
      </v-chip-group>
      <v-list lines="three">
        <v-list-item v-for="item in islands" :key="item.id">
          <div class="row mb-4">
            <div class="col-3" style="max-width: 70px">
              <image-field
                size="mini"
                :disabled="true"
                :show-zoom="false"
                :image-click="true"
                :grid="true"
                :width="0"
                :height="50"
                :value="item.photo"
              />
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col-12 pb-0 title">
                  {{ item.name }} - (Total Voters: {{ item.total_voters }})
                </div>
                <div
                  class="col-12 pt-0 pb-0"
                  style="max-height: 35px; overflow: hidden"
                >
                  {{ item.atoll }}
                </div>
                <div
                  class="col-12 pt-0"
                  style="max-height: 45px; overflow: hidden"
                >
                  <span v-for="v in item.voters" :key="v.status">
                    <v-chip class="ma-2" :color="v.color" text-color="white">
                      {{ v.status }}: {{ v.voters }}
                    </v-chip>
                  </span>
                  <br />
                  {{ item.volunteers }} Volunteers
                </div>
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import ImageField from "@/components/custom/ImageField.vue";
export default {
  created() {
    if (this.allowed_campaigns.length == 1) {
      this.campaign_changed(this.allowed_campaigns[0]);
    }
  },
  components: {
    ImageField,
  },
  data() {
    return {
      valid: true,
      loading: false,
      islands: [],
    };
  },
  methods: {
    campaign_changed(campaign) {
      if (!this.empty(campaign)) {
        this.campaigns_id = campaign.id;
        this.getIslands();
      }
    },
    getIslands() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api("public/islands", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.islands = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "Islands",
};
</script>
